<template>
    <div class="backgrounds">
        <section class="top-bar">
            <TopBar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao"/>
        </section>

        <h4 style="padding-block: 10px; padding-inline: 15px">Selecione o item desejado</h4>

        <div v-for="subitem in item.subitens" :key="subitem.codigo">
            <div @click="selecionouSubitem(subitem)" class="item-row">
                         
                <img v-if="subitemSelecionado != null && subitemSelecionado.codigo == subitem.codigo" src="../../../assets/icons/ic_radio_checked.svg" alt=""  class="icon"  />
                <img v-if="subitemSelecionado == null || subitemSelecionado.codigo != subitem.codigo" src="../../../assets/icons/ic_radio_unchecked.svg" alt=""  class="icon"  />
   
                <div style="display: flex; align-items: center; width: 100%">
                    <p style="color:black; width: 65%; text-align: start; background: transparent;">{{ subitem.descricaoCompleta }}</p>
                    <p style="color:black; width: 35%; text-align: end; background: transparent;">R$ {{ subitem.valorVenda.toFixed(2).replace(".", ",") }}</p>
                </div>

            </div>

        </div>



<!-- subitem FICHA TEC, VARIACAO E PERGUNTAS FORCADASa  etc  -->
 
<!-- perguntas forcadas  -->
        <div  v-if="this.subitemSelecionado != null" style="background:white; margin-top: 23px">

            <ProdutoController
                :item="this.subitemSelecionado"
                @onClickConfirmarEsteItem="retornarProduto"
            />

        </div>


<!-- bottom bar com o preco total          -->

        <BottomBar
        v-if="this.subitemFinalizado != null && this.subitemFinalizado.precoApenasVariacoes != null"
            :quantidade="this.quantidade"
            :temPrecoUnit="true" 
            :temPrecoTotal="true" 
            :precoUnit="this.subitemFinalizado.preco" 
            :precoTotal="this.subitemFinalizado.preco" 
            :precoVariacao = "this.subitemFinalizado.precoApenasVariacoes" 
            :promocao="this.item.promocao"
            :clicarSelecionar="enviarCarrinho"
            :clicarSelecionarNaoPodeAvancar="() => {}" 
            :esconderPrecoUnitario="true"
            :podeAvancar="this.subitemFinalizado.podeAvancar"/>

    </div>
</template>

<script> 

import BottomBar  from "../BottomBar.vue";  
import TopBar from '../TopBar.vue';
import ProdutoController from "./controle/ProdutoController.vue";


export default { 
  
    nome: "TipoComboPorTamanho",

    props: {
        item: Object,
    },
    
    data() {
        return { 
            quantidade: 1,

            // combo itens
            subitemSelecionado: null,
            subitemFinalizado: null,

        }
    }, 

    computed: {

        /**
         * se nao houver  perguntaforcada, ja retornar true dizendo
         * que o usuario pode avancar. Senao, esperar todas as perguntas serem respondidas
         */
        podeAvancar() {
            if (this.subitemSelecionado == null) {
                return false
            }
            return this.subitemSelecionado.podeAvancar
        }
    },

    created() {
        this.mostrar();
        this.scrollToTop();
    },

    methods: {


        /** rolar pra cima quando inicia a pagina */
        scrollToTop() {
            window.scrollTo(0,0);
        },

        mostrar() {
        },

        /**
         * Assim que o usuario seleciona um item do combo, setar todas essas variaveis e ir em frente
         * com as perguntas forcadas e ficha tecnica e variacao
         */
         selecionouSubitem(subitem) {
            this.subitemSelecionado = null
            setTimeout(() => {
                this.subitemSelecionado = subitem  
            }, 250);
        },

     
        retornarProduto(prod) {
            setTimeout(() => {
                this.subitemFinalizado  = prod
            }, 250);
        },



        /** calcula o preco vendo se tem promocao ou nao */
        calcularPrecoParaEnviar() {
            return this.subitemFinalizado.precoTotal
        },


        /**
         * é o metodo que envia a mercadoria para o carrinho envia em fomato de objeto o id, o nome, o preco unitario, a quantidade escolhida
         * e o preco total final do item. Depois de adicionar o item, retora para a pagina anterior
         */
        enviarCarrinho() {

            console.log("preco total")
            console.log(this.subitemFinalizado.precoTota)

            let subitem = this.subitemFinalizado
            subitem.quantidade = 1
     
            let itemFinalizado = {
                tipo: "comboPorTamanho",
                id: this.item.id,
                nome: this.item.nome,
                preco: this.subitemFinalizado.precoTotal,
                valorUnitarioPagoNoSmartPos: this.subitemFinalizado.precoTotal,
                quantidade: this.quantidade,
                precoTotal: this.subitemFinalizado.precoTotal, 
                subitem: subitem
            }
            this.$store.dispatch('setItensCarrinho', itemFinalizado)
            this.$router.back()
        }

    },

    components: {
        BottomBar,
        TopBar,
        ProdutoController,
    }

}
</script>

<style scoped>


.item-row {
    color: black; background: white; display: flex;  text-align: start; padding-block: 15px; padding-inline: 15px;
}
.item-row-sair-fora {
    pointer-events: none;
    position: relative;
    animation: item-sair-fora-anim 0.5s forwards, item-sair-fora-anim2 0.1s forwards 0.5s;

}

@keyframes item-sair-fora-anim {
    from {left: 0}
    to {left: -110%}
}

@keyframes item-sair-fora-anim2 {
    /* from {display: flex} */
    to {display: none}
}

.backgrounds {

    display: flex;
    flex-direction: column;
    background: var(--gradiente-background)
}

.top-bar {
    margin: 0;
}
.bodys{
    /* overflow-y: auto; */
    flex-grow: 1;
}

.icon {
    filter: invert(100%);
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-block: auto;
}

#quantidadeTitulo {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    color: white;
}

#mercadoriaImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
}


#nomeDaMercadoria {
    width: 100%;
    background: black;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 0;
}

.btnMaisOuMenos {
    border-radius: 5px;
    background: var(--primaryDark_azul);
    color: white;
    font-weight: 800;
    font-size: 25px;
    padding: 3px;
    width: 40px;
}

#quantidadeEscolhida {
    font-weight: 800;
    margin: 10px;
    font-size: 25px;
    color: white;
}



</style>