<template>
    <div style="background: var(--cor1-muito-escura)" >

<!-- botao buscar  -->
        <button 
        :class="[classeSelecionada == 'buscar' ? 'btn-search selecionado' : 'btn-search naoselecionado-par']" @click="clicarBuscar">
            <img src="../../assets/icons/ic-search.png" alt="" style="filter: invert(100%); max-width: 4vh; margin-bottom: 0px;">
        </button>

<!-- todas as classes de cardapio -->
        <div v-for="(item, index) in produtos" :key="index" >
            
            <button 
            :style="item.nome.length > 9 ? 'font-size: 9px' : 'font-size: 10px'"  
            :class="[classeSelecionada == item.nome ? 'btn selecionado' : index % 2 == 0 ? 'btn naoselecionado-par' : 'btn naoselecionado-impar' ]" @click="clicarClasse(item.nome)">
                <img :src="item.icone" alt="" >
                <p class="nome"> {{ item.nome }}</p>
            </button>
        </div>

        <div style="height: 150px;"></div>
    </div>
</template>

<script>
import {  mapState } from 'vuex';
/**
 * Menu lateral com todas as classes em forma de botao com o icone e o nome da classe.
 * 
 * @vue-data {Number} [produtos=$store.state.produtos] traz todos os produtos da store
 * @vue-computed {String} [classeSelecionada=state.classeSelecionada] traz do store a classe que foi selecionada
 */
export default {
    name: "MenuClasses",
    data() {
        return {
            mostrarBusca: false,
            produtos: this.$store.state.produtos,
        }
    },
    computed: {
        ...mapState({
            classeSelecionada: state => state.classeSelecionada
        })
    },
    methods: {

        /**
         * Aciona o store e dispara a acao setClasseSelecionada
         * 
         * Alem de mudar a cor do botao classe, ja adiciona os itensVenda no atributo itensVenda
         * do store, mandando tanto o titulo da classe quanto os itensVenda no payload.
         * nomeDoItem é nome do tem que deve vir dos produtos no data.
         */
        clicarClasse(nomeDaClasse) {
            let obj = this.$store.state.produtos.find(o => o.nome == nomeDaClasse);
            let payload = {
                titulo: nomeDaClasse,
                descricao: obj["descricao"],
                itensVenda: obj["itensVenda"]
            }
            this.$store.dispatch('setMostrarBarraBusca', false)   
            this.$store.dispatch('setClasseSelecionada', payload)  
            // window.scrollTo(0,0) 
            window.scroll({top:0,  behavior: "smooth", })
        },

        clicarBuscar() {
            this.mostrarBusca = !this.mostrarBusca
            this.$store.dispatch('setClasseSelecionada', {titulo: "buscar"}) 
            this.$store.dispatch('setMostrarBarraBusca', null)     
        }
    },   
}
</script>

<style scoped>
.btn {
    padding-block: 20px;
    width: 10vh;
    color: white;
    
}

.nome {
  
}

button > img {
    max-width: 8vh;
    margin-bottom: 5px;
}

.btn-search {
    width: 100%;
    height: 50px;
}

.selecionado {
    background: var(--gradiente-contraste);
}

.naoselecionado-par {   
    background: var(--gradiente-claro);
}

.naoselecionado-impar {   
    background: var(--gradiente-escuro);
}


/* desktops  */
@media only screen and (min-width: 720px) {
    .btn {
        padding-block: 20px;
        width: 15vh;
        color: white;
        min-height: 120px;
    }

    .btn-search {
        width: 100%;
        height: 80px;
    }

    .nome {
        font-size: 0.8rem;
    }
}


</style>