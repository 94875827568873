<template>
    <div class="backgrounds">
        <section class="top-bar"> 
            <TopBar :imagem="item.imagem" :titulo="item.nome" :descricao="item.descricao"/>
        </section>

        <EtapasCounter :indexCorrente="etapaIndex" :quantidadeTotal="item.etapas.length" />
 
        <div :class="etapaClassName">
            <cada-etapa 
                :indexEtapaCorrente="etapaIndex"
                :totalEtapas="item.etapas.length" 
                :isPizza="false"
                :etapa="item.etapas[etapaIndex]"
                :cobrarPorEtapa="item.comboEtapaPrecoPorEtapaOuItem"
                @onSelectedItems="onSeletectedItemsNaEtapa"
            /> 
        </div>

    </div>
</template>

<script> 

import EtapasCounter from '@/components/Base/EtapasCounter.vue';
import TopBar from '../TopBar.vue';
import CadaEtapa from './CadaEtapa.vue';

/**
 * Quando o item é do tipo mercadoria. Depois de adicionar o item, retora para a pagina anterior
 * @vue-prop {Object} item é o item especifico escolhido
 * @vue-data {Number} [quantidade = 1] quantidade de mercadorias, sendo que inicia com 1
 * @vue-data {Number} [precoTotal=this.item.preco] é preco da mercadoria
 * @vue-computed {Boolean} [esconderPrecoUnitario] manda como prop para o componente BottomBar se ele deve esconder o preco unitario ou nao. Ele sempre deve
 * esconder caso a quantidade de itens selecionados eja igual a 1, valido apenas para mercadorias; 
 */
export default { 
  
    nome: "TipoComboPorEtapa",
 
    props: {
        item: Object,
    },
    
    data() {
        return { 
            etapaIndex: 0,
            etapaClassName: "etapa",

            /** 
             * Esta var eh um array de arrays. Cada etapa vai voltar um array de itens 
             * ja que cada etapa pode ter um ou mais itnes escolhidos
             */
            todosItensFinalizados: [],
        }
    }, 

    computed: {
        valorFinalComputed() {
            let soma = 0.0
            for (var i = 0; i < this.todosItensFinalizados.length; i++) {
                for (var ii = 0; ii < this.todosItensFinalizados[i].length; ii++) {
                    const item = this.todosItensFinalizados[i][ii]

                    // se for produto, o valor vem na chave [precoTotal] ja com os adicionais
                    if (item.tipo == "produto") {
                        soma += item.precoTotal
                    }
                    // swe for combo por etapa, temos o valor de cada item da etapa na chave [item.valorVendaItem]
                    // pois o usuario pode colocar um valor especial para cada item apenas dentro do combo
                    else if (item.item != null && item.item.valorVendaItem != null && item.item.valorVendaItem != "") {
                        soma += item.item.valorVendaItem
                    } 

             
                    
                    else {
                        soma += item.precoTotal
                    }
                    
                }
            }
            return soma;
        }
      
    },

    created() {
        // this.scrollToTop();
        
//80400428     10 por 30
//80400418     10 por 25
    },

    updated() {
    },

    watch: {
        etapaIndex() {
            this.etapaClassName = "etapa-sair"
            setTimeout(() => {
                this.etapaClassName = "etapa"
            }, 500);
        }
    },

    methods: {
 
        onSeletectedItemsNaEtapa(item) {
            
            this.todosItensFinalizados.push(item)
            if (this.etapaIndex < this.item.etapas.length - 1) {
                this.etapaIndex = this.etapaIndex + 1
            } else {
                this.enviarCarrinho()
            }
        },


        /**
         * Aqui vamos desmontar a var @see this.todosItensFinalizados. Esta var eh um array de arrays.
         * Vamos colocar tudo em um array so
         */
        arrumarArrayDeItensFinalizados() {
            let arrayFinal = [] 
            for (var i = 0; i < this.todosItensFinalizados.length; i++) {

                for (var ii = 0; ii < this.todosItensFinalizados[i].length; ii++) {
                    const item = this.todosItensFinalizados[i][ii]
                    item.quantidade = 1
                    item.indexDaEtapa = i
                    arrayFinal.push(item)
                }
                
            }
            return arrayFinal
        },


        /**
         * é o metodo que envia a mercadoria para o carrinho envia em fomato de objeto o id, o nome, o preco unitario, a quantidade escolhida
         * e o preco total final do item. Depois de adicionar o item, retora para a pagina anterior
         */
        enviarCarrinho() {
            let itemFinalizado = {
                tipo: "comboPorEtapa",
                id: this.item.id,
                nome: this.item.nome,
                preco: this.valorFinalComputed,
                valorUnitarioPagoNoSmartPos: this.valorFinalComputed,
                quantidade: 1,
                precoTotal: this.valorFinalComputed,
                subitens: this.arrumarArrayDeItensFinalizados()
                ///
            }
            this.$store.dispatch('setItensCarrinho', itemFinalizado)
            this.$router.back()
        }

    },
    components: {
    TopBar,
    CadaEtapa,
    EtapasCounter
}

}
</script>

<style scoped>

.etapa {
    animation: mudar-etapa 0.5s forwards;
}
@keyframes mudar-etapa {
    from { transform: translateX(50%); opacity: 0;}
    to { transform: translateX(0%); opacity: 1;}
}
.etapa-sair {
    animation: mudar-etapa-sair 0.5s forwards;
}
@keyframes mudar-etapa-sair {
    from { transform: translateX(0%); opacity: 1;}
    to { transform: translateX(-50%); opacity: 0;}
}


.item-row {
    color: black; background: white; display: flex;  text-align: start; padding-block: 15px; padding-inline: 15px;
}
.item-row-sair-fora {
    pointer-events: none;
    position: relative;
    animation: item-sair-fora-anim 0.5s forwards, item-sair-fora-anim2 0.1s forwards 0.5s;

}

@keyframes item-sair-fora-anim {
    from {left: 0}
    to {left: -110%}
}

@keyframes item-sair-fora-anim2 {
    /* from {display: flex} */
    to {display: none}
}

.backgrounds {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.top-bar {
    margin: 0;
}
.bodys{
    /* overflow-y: auto; */
    flex-grow: 1;
}

.icon {
    filter: invert(100%);
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-block: auto;
}

#quantidadeTitulo {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    color: white;
}

#mercadoriaImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
}


#nomeDaMercadoria {
    width: 100%;
    background: black;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 0;
}

.btnMaisOuMenos {
    border-radius: 5px;
    background: var(--primaryDark_azul);
    color: white;
    font-weight: 800;
    font-size: 25px;
    padding: 3px;
    width: 40px;
}

#quantidadeEscolhida {
    font-weight: 800;
    margin: 10px;
    font-size: 25px;
    color: white;
}



</style>