import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CardapioView from '../views/CardapioView.vue'
import CadaItemView from '../views/CadaItemView.vue'
import MenuEstaticoItemView from '../views/MenuEstaticoItemView.vue'
import CarrinhoView from '../views/CarrinhoView.vue'
import ContaView from '../views/ContaView.vue'
import ChamarGarcomView from '../views/ChamarGarcomView.vue'
import TesteView from '../views/TesteView.vue'
import AbaDestaques from "../views/AbaDestaques.vue"
import QrcodeScanner from "../utils/QrcodeScanner.vue"
import UserLogin from "../views/UserLogin.vue"
import StatusDeliveryView from "../views/StatusDeliveryView.vue"
import DeliveryView from "../views/DeliveryView.vue"
import DeliveryEnderecoView from "../views/DeliveryEndereco.vue"
import DeliverySucessoView from "../views/DeliverySucessoView.vue"
import MpPagamento from "../components/mercadopago/MpPagamento.vue"
import ErroVersao from "../views/ErroVersao.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: UserLogin,
  },
  {
    path: '/cardapio',
    name: 'cardapio',
    component: CardapioView
  },
  {
    path: '/carrinho',
    name: 'carrinho',
    component: CarrinhoView
  },
  {
    path: '/item',
    name: 'item',
    props: true,
    component: CadaItemView
  },
  {
    path: '/menu-estatico-item',
    name: 'menu-estatico-item',
    props: true,
    component: MenuEstaticoItemView
  },
  {
    path: '/conta',
    name: 'conta',
    props: false,
    component: ContaView
  },
  {
    path: '/statusdelivery',
    name: 'statusdelivery',
    props: false,
    component: StatusDeliveryView
  },
  {
    path: '/garcom',
    name: 'garcom',
    props: false,
    component: ChamarGarcomView
  },
  {
    path: '/destaques',
    name: 'destaques',
    props: false,
    component: AbaDestaques
  },
  {
    path: '/camera',
    name: 'camera',
    props: false,
    component: QrcodeScanner
  },
  {
    path: '/teste',
    name: 'teste',
    props: false,
    component: TesteView
  },
  {
    path: '/delivery',
    name: 'DeliveryView',
    props: false,
    component: DeliveryView
  },
  {
    path: '/deliveryendereco',
    name: 'DeliveryView',
    props: false,
    component: DeliveryEnderecoView
  },
  {
    path: '/deliverysucesso',
    name: 'DeliverySucessoView',
    props: false,
    component: DeliverySucessoView
  },
  {
    path: '/pagamento',
    name: 'MpPagamento',
    props: true,
    component: MpPagamento
  },
  {
    path: '/erroversao',
    name: 'ErroVersao',
    props: true,
    component: ErroVersao
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  /** vai rolar pro todo toda mudanca de rota */
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  },
  routes
})

export default router
