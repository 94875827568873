import { render, staticRenderFns } from "./CadaEtapa.vue?vue&type=template&id=71e0dbae&scoped=true&"
import script from "./CadaEtapa.vue?vue&type=script&lang=js&"
export * from "./CadaEtapa.vue?vue&type=script&lang=js&"
import style0 from "./CadaEtapa.vue?vue&type=style&index=0&id=71e0dbae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e0dbae",
  null
  
)

export default component.exports