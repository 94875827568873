import axios from "axios";
import { rotaDeProducao } from "./rotas";
import { CONSOLOGAR } from "@/components/Controle/debug";


/**
 * Busca o status da mesa ou do cartao
 * @param {String} cnpj 
 * @param {int} idTerminal 
 * @param {int} numeroEmpresaFK 
 * @returns {Object}   {"Id": 1,"numero_mesa": 22,"status": "LIVRE","valor_total": null} 
 */
export async function apiBuscarStatusMesa(cnpj, idTerminal, numeroEmpresaFK, numeroCartao) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

    try {
        // para testes --> cnpj=00906885019092 idTerminal=72
        let urlProd = null
        if (numeroCartao != null) {
            urlProd = rotaDeProducao +"/statuscartao?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal + "&numeroEmpresaFK=" + numeroEmpresaFK + "&numeroCartao=" + numeroCartao; 
        } else {
            urlProd = rotaDeProducao +"/status?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal + "&numeroEmpresaFK=" + numeroEmpresaFK; 
        }
        CONSOLOGAR(['urlProd', urlProd])
        const data = await axios.get(urlProd);
        CONSOLOGAR(['res do apiBuscarStatusMesa', data.data])
        let json = data.data; 
        return json;
    } catch (e) {
        CONSOLOGAR([e])
    }
}

