import axios from "axios";
import { VERSAO, rotaDeProducao } from "./rotas";
import { CONSOLOGAR } from "@/components/Controle/debug";

export async function buscarTodosItens(isDelivery) {
    console.log("is delivery")
    console.log(isDelivery)
    CONSOLOGAR([window.location.href]);
    let uri = window.location.href.split('?');

    
    let params = null
    let cnpj = null
    let idTerminal = null
    let isMenuEstatico = null
    
    // caso seja o baitnazha, redirecionar para o cnpj deles
    if (window.location.href.includes("baitnazha")) {
        cnpj = 29274029000143
        idTerminal= 17
        isMenuEstatico = false;
        console.log("baitznazha delivery")
        console.log(`baitznazha delivery ${29274029000143} e terminal ${idTerminal}`)        
    } else {
        params = uri[1].split("--");
        cnpj = params[0];
        idTerminal = params[1];
        isMenuEstatico = params[2] == null ? false : true;
    }

    try {
        // let urlProd = rotaDeProducao +"/itens?cnpj=" + cnpj + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery + "&versao=" + VERSAO;   // para testes assim ==> http://localhost:8080/?00906885412592--25
        console.log(VERSAO)
        let urlProd = rotaDeProducao +"/itens?cnpj=" + cnpj + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery + "&versao=" + VERSAO;
        console.log("url =>", urlProd);
        const data = await axios.get(urlProd);
        //
        // caso o cliente esteja com o allfood na versao errada
        console.log(`resposta => ${urlProd}`)
        console.log(data)
        if (data.status == 409) {
            throw (data.data)
        } else {
            let json = data.data;
            json['cnpj'] = cnpj;
            json['idTerminal'] = idTerminal;
            json['isMenuEstatico'] = isMenuEstatico;
            json['isDelivery'] = isDelivery;
            return json;
        }

    } catch (e) {
        if (e.response.status == 409) {
            throw e.response.data.erro
        } else 
        throw e;
    }
    
}

export async function buscarRefresh(cnpj, idTerminal,  isMenuEstatico, isDelivery) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    try {
        let urlProd = rotaDeProducao +"/itens?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery;   // para testes --> cnpj=00906885019092 idTerminal=72
        // let urlProd = rotaDeProducao +"/pizza?cnpj=" + cnpjConvertido + "&idTerminal=" + idTerminal + "&isMenuEstatico=" + isMenuEstatico + "&isDelivery=" + isDelivery;   // para testes --> cnpj=00906885019092 idTerminal=72
        const data = await axios.get(urlProd);
        let json = data.data;
        json['cnpj'] = cnpjConvertido;
        json['idTerminal'] = idTerminal;
        return json;
    } catch (e) {
        CONSOLOGAR([e])
    }
}

