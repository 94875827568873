
//
//
//------------------------ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ROTA de PRODUCAO ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼
export const rotaDeProducao = "https://analytics-api.dalcatech.com.br/cardapio/v3"; // PRESTAR ATENCAO na versao v2 ou v3 !!!!
// export const rotaDeProducao = "https://api-teste.dalcatech.com.br/cardapio/v3"; // PRESTAR ATENCAO na versao v2 ou v3 !!!!



//
// ---------------------------- ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ROTA de TESTE ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ --------------------------------------
// export const rotaDeProducao = "http://192.168.100.231:8000/cardapio/v3"; //PRESTAR ATENCAO no ip desta maquina!!!
// export const rotaDeProducao = "http://192.168.100.28:8000/cardapio/v2";


export const VERSAO = "1.0.0" // IMPORTANT! lembrar disso aqui que vai na rota buscaritens


// 
// 
// para testes = http://localhost:8080/?00906885412592--25
