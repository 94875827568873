<template>
    <div class="corpo">
        <h4>{{ nomeDoItem }}</h4>

<!-- pergunta forcada  -->
        <div v-if="item.perguntaforcada.length != 0" class="card-ficha">
            <div v-for="(perguntaForcada, index) in item.perguntaforcada" :key="index">
                <p style="color: red; font-weight: bold;">Obrigatório</p>
                <h4>{{ perguntaForcada.descricao }}</h4>
                <div v-for="(opcao, index2) in perguntaForcada.opcoes" :key="index2">
                    <div class="opcao-selecionar-radio" @click="onClickPerguntaForcada(perguntaForcada, opcao)">
                        <img v-if="verificarSeEstaPerguntaForcadaFoiEscolhida(perguntaForcada, opcao)" src="../../../../assets/icons/ic_radio_checked.svg" alt="" style="height: 35px">
                        <img v-if="!verificarSeEstaPerguntaForcadaFoiEscolhida(perguntaForcada, opcao)" src="../../../../assets/icons/ic_radio_unchecked.svg" alt="" style="height: 35px">
                        <p>{{ opcao.descricao }}</p>
                    </div>
                </div>
                
                <!-- apenas para ter espacamento entre as perguntas, mas nao ter na ultima -->
                <div v-if="index < item.perguntaforcada.length - 1">
                    <br>
                </div>
            </div>
        </div>


<!-- ficha tec  -->
        <div v-if="item.fichatecnica && item.fichatecnica.length != 0" class="card-ficha">
            <h5>Deseja retirar algum item?</h5>
            <div v-for="(ficha, index) in item.fichatecnica" :key="index">
                <div v-if="ficha.poderemover == 'true' || ficha.poderemover == true" class="opcao-selecionar"  @click="onClickRetirarItem(ficha)">
                    <img v-if="itemsRetirar.findIndex(i => i.id == ficha.id) == -1" src="../../../../assets/icons/ic-checked.png" alt="" style="height: 35px">
                    <img v-if="itemsRetirar.findIndex(i => i.id == ficha.id) !== -1" src="../../../../assets/icons/ic-unchecked.png" alt="" style="height: 35px">
                    <p>{{ ficha.nome }}</p>
                </div>
                <div v-if="ficha.poderemover == 'false' || ficha.poderemover == false" class="opcao-selecionar" >
                    <img v-if="itemsRetirar.findIndex(i => i.id == ficha.id) == -1" src="../../../../assets/icons/ic-block.svg" alt="" style="height: 35px">
                    <p style="color: RGB(120,120,120)">{{ ficha.nome }}</p>
                </div>
            </div>
        </div>
        
        
<!-- variacao ficha tec  -->
        <div v-if="item.variacaofichatecnica &&  item.variacaofichatecnica.length != 0" class="card-ficha">
            <h5>Deseja adicionar algum item?</h5>
            <br>

            <div v-for="(variacao, index) in item.variacaofichatecnica" :key="index" class="card-variacao">
                <h4>{{ variacao.pergunta.nomeVariacao }}</h4>
                <div class="row">
                    <p :class="classQtdMax" style="font-weight: bold;" :ref="'refMax'">max: {{ variacao.pergunta.quantidadeMaxima }}</p>
                    <p v-if="variacao.pergunta.ExigirLancamento == 1 || variacao.pergunta.ExigirLancamento == '1'" style="color: red; font-weight: bold;">Obrigatório</p>
                    <p v-if="variacao.pergunta.ExigirLancamento == 0 || variacao.pergunta.ExigirLancamento == '0'" style="color: green; font-weight: bold;">Opcional</p>
                </div>
                <div v-for="(opcao, index2) in variacao.opcoes" :key="index2">
                    <div class="opcao-selecionar"  @click="onClickAdicionarItem(variacao.pergunta, opcao, index)">
                        <img v-if="verificarSeEstaVariacaoJaFoiEscolhida(variacao.pergunta, opcao) == true" src="../../../../assets/icons/ic-checked.png" alt="" style="height: 35px">
                        <img v-if="verificarSeEstaVariacaoJaFoiEscolhida(variacao.pergunta, opcao) == false" src="../../../../assets/icons/ic-unchecked.png" alt="" style="height: 35px">
                        <div class="row">
                            <p>{{ opcao.descricaoCompleta }}</p>
                            <p v-if="isSaborPizza == null || isSaborPizza == false">R$ {{ opcao.valorAdicionais.toFixed(2).replace(".", ",") }}</p>
                            <p v-if="isSaborPizza == true">R$ {{ calcularValorAdicionalCasoSejaPizza(opcao.valorAdicionais).toFixed(2).replace(".", ",") }}</p>
                        </div>
                    </div>
                </div>

                <!-- apenas para ter espacamento entre as variacoes, mas nao ter na ultima -->
                <div v-if="index < item.variacaofichatecnica.length - 1">
                    <br>
                    <br>
                </div>
            </div>
            
        </div>

        <!-- mensagem quando o usuario nao respondeu todas as obrigacoes ainda  -->
        <div v-if="!todasVariacoesRespondidas || !todasPerguntasForcadasRespondidas" class="msg-responda-obrigatorias">
            <p>Antes de confirmar, responda todas as perguntas obrigatórias.</p>
        </div>   
       

    
    
    </div>
</template>

<script>
export default {
    name: "ProdutoController",
    props: {
        item: Object,
        isSaborPizza: Boolean,
        qtdSaboresEscolhidos: Number,
        itemIndex: Number,

        /**
         * caso precise de um preco especifico para este item, ele deve vir aqui.
         * caso este Prop venha nulo, usaremos o preco do item
         */
        precoProps: Number
        // emits the function @onClickConfirmarEsteItem(itemFinalizado)
    },
    
    data() {
        return {
            classQtdMax: 'qtd-max',
            perguntasForcadas: [],
            itemsRetirar: [],
            itemsAdicionar: [],
            jaConfirmado: false
        }
    },
    created() {
        this.onClickConfirmar()
    },
    watch:{
        /**
         * Vamos limpar todas as variacoes e fichatec escolhidas anteriormente caso o usuario selecione outro item
         * mesmo apos ja ter selecionado um anterior
         */
        item() {
            this.mudarDeItemSelecionado()
        }
    },
    computed: {

        temVariacaoFichaTec() {
            return this.item.variacaofichatecnica != null
        },

        nomeDoItem() {
            if (this.item.nome != null) return this.item.nome
            if (this.item.descricao != null) return this.item.descricao
            return this.item.descricaoCompleta
        },

        todasPerguntasForcadasRespondidas() {
            if (this.item.perguntaforcada == null) return true
            else if (this.item.perguntaforcada.length == this.perguntasForcadas.length ) return true
            else return false
        },

        quantidadeVariacoesObrigatoriasTotal() {
            if (this.temVariacaoFichaTec) {
                let qtdVariacoesObrigatorias = 0
                for (var i = 0; i < this.item.variacaofichatecnica.length; i++) {
                    const variac = this.item.variacaofichatecnica[i]
                    if (variac.pergunta.ExigirLancamento == "1" || variac.pergunta.ExigirLancamento == 1) {
                        qtdVariacoesObrigatorias = qtdVariacoesObrigatorias + 1
                    }   
                }
                return qtdVariacoesObrigatorias
            } else {
                return 0
            }
        },

        quantidadeVariacoesObrigatoriasRespondidas() {
            if (this.temVariacaoFichaTec) {
                return this.itemsAdicionar.filter(i => parseInt(i.obrigatoria) == 1 && i.opcoesEscolhidasId.length > 0).length
            } else {
                return 0
            }
        },

        todasVariacoesRespondidas() {
            return this.quantidadeVariacoesObrigatoriasTotal == this.quantidadeVariacoesObrigatoriasRespondidas
        },

        /**
         * Vamos retornar o preco ou a promocao dependendo se tem promocao
         * 
         * Temos que checar se o objeto "item" tem a chave "valorPromocao" ou "valorVenda" - sao as chaves que vem nos combos;
         * 
         * Caso contrario, as chves vem como "promocao" e "preco"
         */
        precoComputed() {
            if (this.precoProps != null) {
                return this.precoProps
            }
            if (this.item.valorPromocao != null && this.item.valorPromocao != 0) {
                return this.item.valorPromocao;
            }
            if (this.item.promocao != null && this.item.promocao != 0) {
                return this.item.promocao;
            }
            if (this.item.valorVendaItem != null && this.item.valorVendaItem != 0) {
                return this.item.valorVendaItem;
            }
            if (this.item.valorVenda != null && this.item.valorVenda != 0) {
                return this.item.valorVenda;
            }
            return this.item.preco
        },

        podeAvancar() {
            if (this.todasVariacoesRespondidas && this.todasPerguntasForcadasRespondidas) return true
            return false
        }

    },
    methods: {
        
        onClickRetirarItem(item) {
            let filtered = this.itemsRetirar.filter(i => i.id == item.id)

            if (filtered.length == 0) {
                this.itemsRetirar.push(item)
            } else {
                this.itemsRetirar = this.itemsRetirar.filter(i => i.id !== item.id)
            }

            this.onClickConfirmar()
        },

        onClickAdicionarItem(pergunta, opcaoEscolhida, indexOrdemQueAparece) {
            let perguntaOpcao = {
                perguntaId: pergunta.id ?? pergunta.Id,
                pergunta: pergunta.nomeVariacao,
                obrigatoria: pergunta.ExigirLancamento,
                max: pergunta.quantidadeMaxima
            }

            let indexPergunta = this.itemsAdicionar.findIndex(i => i.perguntaId == pergunta.Id)

            if (indexPergunta == -1) {
                perguntaOpcao.opcoesEscolhidasId = []
                perguntaOpcao.opcoesEscolhidas = []
                perguntaOpcao.opcoesEscolhidasId.push(opcaoEscolhida.codigo)
                perguntaOpcao.opcoesEscolhidas.push({
                    nome: opcaoEscolhida.descricaoCompleta,
                    preco: this.isSaborPizza ? this.calcularValorAdicionalCasoSejaPizza(opcaoEscolhida.valorAdicionais) : opcaoEscolhida.valorAdicionais
                })
                this.itemsAdicionar.push(perguntaOpcao)
            } else {
                let estaPergunta = this.itemsAdicionar[indexPergunta]
                if (estaPergunta.opcoesEscolhidasId == null) {
                    estaPergunta.opcoesEscolhidasId = []
                    estaPergunta.opcoesEscolhidas = []
                }

                // verificar se a opcao ja nao esta la
                let indexOpcao = estaPergunta.opcoesEscolhidasId.findIndex(i => i == opcaoEscolhida.codigo)

                if (indexOpcao == -1) {
                    //
                    // antes de adicionar na lista, vamos verificar se ja nao atingimos a quantidade max de opcoes respondidas
                    if (pergunta.quantidadeMaxima !== estaPergunta.opcoesEscolhidasId.length ) {
                        estaPergunta.opcoesEscolhidasId.push(opcaoEscolhida.codigo)
                        estaPergunta.opcoesEscolhidas.push({
                            nome: opcaoEscolhida.descricaoCompleta,
                            preco: this.isSaborPizza ? this.calcularValorAdicionalCasoSejaPizza(opcaoEscolhida.valorAdicionais) : opcaoEscolhida.valorAdicionais
                        })
                    } else {
                        this.animarQuantidadeMaxAtingida(indexOrdemQueAparece)
                    }
                } else {
                    estaPergunta.opcoesEscolhidasId = estaPergunta.opcoesEscolhidasId.filter((o) => o !== opcaoEscolhida.codigo)
                    estaPergunta.opcoesEscolhidas = estaPergunta.opcoesEscolhidas.filter((o) => o.nome !== opcaoEscolhida.descricaoCompleta)
                }
            }

            this.onClickConfirmar()
        },

        animarQuantidadeMaxAtingida(index) {
            let thisItem = this.$refs.refMax[index];
            thisItem.className = "qtd-max-anim"
            setTimeout(() => {
                thisItem.className = "qtd-max"
            }, 1000);
        },

        /**
         * Temos que calcular o valor do adicional divido pelo numero de sabores esoclhidos.
         * O preco do adicional varia de acordo com a quantidade de sabores escolhidos.
         */
        calcularValorAdicionalCasoSejaPizza(precoAdicional) {
            return precoAdicional / this.qtdSaboresEscolhidos

        },

        verificarSeEstaVariacaoJaFoiEscolhida(pergunta, opcaoEscolhida) {
            let indexPergunta = this.itemsAdicionar.findIndex(i => i.perguntaId == pergunta.Id)
            if (indexPergunta == -1) {
                return false
            } else {
                let estaPergunta = this.itemsAdicionar[indexPergunta]

                // verificar se a opcao ja nao esta la
                let indexOpcao = estaPergunta.opcoesEscolhidasId.findIndex(i => i == opcaoEscolhida.codigo)

                if (indexOpcao == -1) {
                    return false
                } else {
                    return true
                }
            }
        },

        onClickPerguntaForcada(pergunta, opcaoEscolhida) {
            let perguntaOpcao = {
                perguntaId: pergunta.id,
                opcaoEscolhidaId: opcaoEscolhida.idobservacao,
                opcaoEscolhida: opcaoEscolhida.descricao
            }

            let indexDestaPergunta = this.perguntasForcadas.findIndex((p) => p.perguntaId == pergunta.id)
            
            if (indexDestaPergunta == -1) {
                this.perguntasForcadas.push(perguntaOpcao)
            } else {
                this.perguntasForcadas[indexDestaPergunta].opcaoEscolhidaId = opcaoEscolhida.idobservacao
                this.perguntasForcadas[indexDestaPergunta].opcaoEscolhida = opcaoEscolhida.descricao
            }

            this.onClickConfirmar()
        },
                
        verificarSeEstaPerguntaForcadaFoiEscolhida(pergunta, opcao) {
            let i = this.perguntasForcadas.findIndex((p) => p.perguntaId == pergunta.id)
            if (i == -1 ) {
                return false
            } else {
                if (this.perguntasForcadas[i].opcaoEscolhidaId == opcao.idobservacao) {
                    return true
                } else {
                    return false
                }
            }
        },

        mudarDeItemSelecionado() {
            this.perguntasForcadas = []
            this.itemsRetirar = []
            this.itemsAdicionar = []
        },

        arrumarTipo() {
            if (this.item.tipo == null) {
                if (parseInt(this.item.tipoItemVenda) == 1) {
                    return "mercadoria"
                } else {
                    return "produto"
                }
            }
            return this.item.tipo
        },

        calcularPrecoDasVariacoes() {
            if (this.itemsAdicionar != null && this.itemsAdicionar.length > 0) {
                let valorDosAdicionais = 0.0

                for (var i = 0; i < this.itemsAdicionar.length; i++) {
                    if (this.itemsAdicionar[i].opcoesEscolhidas != null && this.itemsAdicionar[i].opcoesEscolhidas.length > 0) {

                        for (var ii = 0; ii < this.itemsAdicionar[i].opcoesEscolhidas.length; ii++) {
                            valorDosAdicionais += this.itemsAdicionar[i].opcoesEscolhidas[ii].preco
                        }
                    }
                }

                return valorDosAdicionais
            } else {
                return 0.0
            }    
        },


        calcularPrecoTotalComVariacoes() {
            return this.precoComputed + this.calcularPrecoDasVariacoes()
        },

        onClickConfirmar() {
            // if (this.podeAvancar) {

                // let precoApenasVariacoes = this.calcularPrecoDasVariacoes()
                let precoTotalComVariacoes = this.calcularPrecoTotalComVariacoes()
                
                const itemFinalizado = {
                    podeAvancar: this.podeAvancar,
                    item: this.item,
                    quantidadeCombo: this.item.quantidade, // so para combos venda conjuta (cada subitem tem uma quantidade, so precisamos repassar pra frente)
                    tipo: this.arrumarTipo(),
                    id: this.item.id ?? this.item.codigo,
                    codigo: this.item.codigo,
                    nome: this.nomeDoItem,
                    preco: this.precoComputed, // preco sem variacoes
                    precoApenasVariacoes:  this.calcularPrecoDasVariacoes(), // preco APENAS das variacoes
                    valorUnitarioPagoNoSmartPos: this.precoComputed, // preco sem variacoes
                    fichaTecnica: null,
                    quantidade: this.quantidade,
                    retirar: this.itemsRetirar,
                    adicionar: this.itemsAdicionar,
                    perguntasForcadas: this.perguntasForcadas,
                    precoTotal: precoTotalComVariacoes, // preco com variacoes
                }


                if (this.precoProps != null) {
                    itemFinalizado.item.valorVendaItem = precoTotalComVariacoes
                }
                // if (this.precoProps != null) {
                //     // itemFinalizado.item.valorVendaItem = precoTotalComVariacoes
                //     itemFinalizado.item.valorVendaItem = this.precoProps
                // }

                // vamos retornar o produto finalizado e tbm o index deste item
                // para que no Parent possamos encotrar este item na lista de produtos
                this.$emit('onClickConfirmarEsteItem', itemFinalizado, this.itemIndex)

                this.jaConfirmado = true
            // }
        }
    }

}
</script>

<style scoped>
.corpo { 
    background-color: white;
    padding-block: 10px;
    margin: 5px;
    border-radius: 10px;
}

.card-ficha {
    border: solid 2px black;
    margin-inline: 10px;
    margin-bottom: 10px;
    padding:  10px;
    border-radius: 12px;
}

.card-variacao {}

p, h1, h2, h3, h4, h5 { color: black; }

.row {
    flex: 1;
    display: flex;
    justify-content: space-between; 
    margin-block: 4px;
}

.opcao-selecionar, .opcao-selecionar-radio { 
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-content: center;
    align-items: center;
    margin-block: 8px;
}

.opcao-selecionar-radio > img {
    filter: invert(100%);
    max-height: 20px;
    max-width: 20px;
    margin-right: 10px;
}

.opcao-selecionar > img {
    filter: invert(0%);
    margin-right: 10px;
    max-height: 22px;
    max-width: 22px;
}

.botao-avancar-container {}

.botao-avancar-container .btn-avancar{
    width: calc(100% - 20px);
    border-end-start-radius: 10px;
    border-end-end-radius: 10px;
    height: 50px;
    margin: 10px;
}

.btn-avancar-confirmado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--cor1-muito-escura);
    color: grey;
}

.btn-desativado {
    background: rgb(120, 120, 120);
    color: rgb(85, 85, 85);
}

.msg-responda-obrigatorias {
    width: calc(100% - 20px);
    padding: 10px;
}


.qtd-max {

}
.qtd-max-anim {
    /* padding-inline: 5px; */
    border-radius: 5px;
    animation: qtd-max-animation 1s forwards;
}

@keyframes qtd-max-animation {
    0%, 100% {background: none;}
    10%      {background: red;}
}

.img-confirmado {
    height: 60%;
    filter: invert(70%);
    margin-right: 15px;
}

</style>