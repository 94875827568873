import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import Vue from "vue"
import json from "../assets/mocks/produtos.json"

import { buscarRefresh, buscarTodosItens } from "@/api/ApiPegarItens";
import ApiDelivery from "@/api/ApisDelivery";
import Swal from "sweetalert2";
/**
 * @module Store 
 */
Vue.use(Vuex)
/**
 * Na primeira vez que os dados vem da api, eles sao setados aqui mas a pagina inicial HomeView.vue ainda nao carrega
 * por isso, na HomeView.vue usamos a resposta direta do axios ao inves de dados do store
 */
export default new Vuex.Store({
    /** 
     * Aqui vem os plugins. 
     * O unico plugin usado é createPersistedState() - para os dados persistirem mesmo quando o user da um refresh na pagina
     */
    plugins: [createPersistedState()],

    state: {
        loading: false,
        isMenuEstatico: false,  //vem na url, no final "--estatico"
        porCartao: json["porCartao"],
        organizarPorColunas: false,
        mesa: json["mesa"],
        numeroEmpresaFK: json["numeroEmpresaFK"],
        idgarcom: json["idgarcom"],
        codigoCentroResultado: json["codigoCentroResultado"],
        empresa: "",
        cnpj: "",
        idTerminal: 0,
        endereco: json["endereco"],
        logo: "",
        cor1: "",
        cor2: "",
        backgroundImage: "",
        produtos: json['classes'],
        classeSelecionada: "",
        classeSelecionadaDescricao: "",
        itemSelecionado: {},
        itensVenda: [],
        destaques: [],
        itensCarrinho: [],
        animarCarrinho: false,        
        itemTemporario: [],
        etapaSelecionada: [],
        statusMesa: "",
        totalContaMesa: null,
        abaSelecionada: "cardapio",
        mostrarBarraBusca: false,
        itemBuscado: null,
        pizzaCobrarMaior: true,

        //bottom bar enviar pedido
        onClickEnviarPedido: 0,

        // para saber se mostra a camera para escanear codigo de barras
        cartaoComLeitorCodigoBarras: false,

        // apenas pro delivery 
        isDelivery: false, 
        casaAberta: true, 
        deliveryEndereco: null,
        deliveryRetirarNoLocal: false,
        deliveryTaxas: null,
        deliveryPedido: null,
        mp_public_key: null,
    },
    
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        SET_DELIVERY(state, payload) {
            state.isDelivery = payload
        },
        SET_ON_CLICK_ENVIAR_PEDIDO(state, payload) {
            state.onClickEnviarPedido = payload
        },
        SET_EMPRESA(state, payload) {
            state.empresa = payload
        },
        SET_POR_CARTAO(state, payload) {
            state.porCartao = payload
        },
        SET_ORGANIZAR_POR_COLUNAS(state, payload) {
            state.organizarPorColunas = payload
        },
        SET_MESA(state, payload) {
            state.mesa = payload
        },
        SET_IDS(state, payload) {
            state.cnpj = payload.cnpj.toString(),
            state.idTerminal = payload.idTerminal.toString(),
            state.numeroEmpresaFK = payload.numeroEmpresaFK,
            state.idgarcom = payload.idgarcom,
            state.codigoCentroResultado = payload.codigoCentroResultado
        },

        SET_ENDERECO(state, payload) {
            state.endereco = payload
        },
        SET_PRODUTOS(state, payload) {
            state.produtos = payload
        },
        SET_DESTAQUES(state, payload) {
            state.destaques = payload
        },
        SET_LOGO(state, payload) {
            state.logo = payload
        },
        SET_CORES(state, payload) {
            state.cor1 = payload.cor1
            state.cor2 = payload.cor2
        },
        SET_BACKGROUND_IMAGE(state, payload) {
            state.backgroundImage = payload
        },
        SET_IS_MENU_ESTATICO(state, payload) {
            state.isMenuEstatico = payload
        },
        SET_CURRENT(state, payload) {
            state.currentStr = payload
        },
        SET_CLASSE_SELECIONADA(state, payload) {
            state.classeSelecionada = payload.titulo;
            state.classeSelecionadaDescricao = payload.descricao;
        },
        SET_ITENS_VENDA(state, payload) {
            state.itensVenda = payload;
        },
        SET_ITEM_SELECIONADO(state, payload) {
            state.itemSelecionado = payload;
        },
        SET_ETAPA_SELECIONADA(state, payload) {
            state.etapaSelecionada = payload;
        },
        SET_ITENS_CARRINHO(state, payload) {
            state.itensCarrinho.push(payload);
            state.animarCarrinho = true;
            setTimeout(() => {
                state.animarCarrinho = false;
            }, 900);
        },
        SET_LIMPAR_ITENS_CARRINHO(state) {
            state.itensCarrinho = [];
        },

        SET_CARTAO_LEITOR_CODIGO_BARRAS(state, payload) {
            state.cartaoComLeitorCodigoBarras = payload == "1" ? true : false;
        },

        SET_LIMPAR_TUDO(state) {
            state.itensCarrinho = [];
            state.produtos = [];
            state.classeSelecionada = "";
            state.itemSelecionado = {};
            state.itensVenda = [];
            state.destaques = [];
            state.itensCarrinho = [];
            state.animarCarrinho = false;        
            state.itemTemporario = [];
            state.etapaSelecionada = [];
        },
        SET_LIMPAR_QUASE_TUDO_REFRESH(state) {
            state.produtos = [];
            state.itensVenda = [];
            state.destaques = [];
        },
        SET_DELETAR_ITEM_CARRINHO(state, payload) {
            const index = state.itensCarrinho.indexOf(payload);
            if (index > -1) { // only splice array when item is found
                state.itensCarrinho.splice(index, 1); // 2nd parameter means remove one item only
            } 
        },
        SET_ITEM_TEMPORARIO(state, payload) {
            state.itemTemporario.push(payload) ;
        },
        SET_LIMPAR_ITEM_TEMPORARIO(state) {
            state.itemTemporario = [];
        },
        SET_STATUS_E_CONTA(state, payload) {
            state.statusMesa = payload.statusMesa,
            state.totalContaMesa = payload.totalContaMesa
        },
        SET_ABA_SELECIONADA(state, payload) {
            state.abaSelecionada = payload;
        },
        SET_MOSTRAR_BARRA_BUSCA(state, payload) {
            state.mostrarBarraBusca = payload;
        },
        SET_ITEM_BUSCADO(state, payload) {
            state.itemBuscado = payload;
        },
        SET_IS_DELIVERY(state, payload) {
            state.isDelivery = payload;
        },
        SET_DELIVERY_ENDERECO(state, payload) {
            state.deliveryEndereco = payload;
        },
        SET_DELIVERY_RETIRAR_NO_LOCAL(state, payload) {
            state.deliveryRetirarNoLocal = payload;
        },
        SET_DELIVERY_TAXAS(state, payload) {
            state.deliveryTaxas = payload;
        },
        SET_DELIVERY_PEDIDO(state, payload) {
            state.deliveryPedido = payload;
        },
        SET_CASA_ABERTA(state, payload) {
            state.casaAberta = payload;
        },
        SET_PIZZA_COBRAR_MAIOR(state, payload) {
            state.pizzaCobrarMaior = payload;
        },
        SET_TEM_MERCADO_PAGO(state, payload) {
            console.log("mercado pago aqui")
            console.log(payload)
            if (payload == null) {
                state.mp_public_key = null;
            } else if (payload.public_key == null) {
                state.mp_public_key = null;
            } else {
                state.mp_public_key = payload.public_key;
            }
        },
    },

    /**
     * Acoes e funcoes realizadas no Store
     */
    actions: {
        
        /**
         * @function buscarTudo()
         * @desc Metodo usado para consumir a API e pegar os primeiros dados do cliente com os produtos do cardapio
         * @param {*} state 
         * @returns Respone
         */
        async buscarTudo(state) {
            state.commit("SET_LIMPAR_TUDO");
            state.commit('SET_IS_DELIVERY', false )
            return buscarTodosItens(this.state.isDelivery).then((res) => {
                // limpar o local storage
                localStorage.clear();
       
                // commits feitos no state
                state.commit("SET_DELIVERY", this.state.isDelivery); 
                state.commit("SET_EMPRESA", res.nomeFantasia);
                state.commit("SET_POR_CARTAO", res.formaTrabalho == "cartao" ? true : false); //TODO fix this
                // state.commit("SET_POR_CARTAO", true); //TODO fix this
                state.commit("SET_ORGANIZAR_POR_COLUNAS", res.organizarPorColunas ?? false);
                state.commit("SET_MESA", res.mesa);
                state.commit("SET_IS_MENU_ESTATICO", res.isMenuEstatico ?? false); // vem na url, no final "--estatico"
                state.commit("SET_ENDERECO", res.endereco);
                state.commit("SET_PRODUTOS", res.classes);
                state.commit("SET_DESTAQUES", res.destaques);
                state.commit("SET_LOGO", res.personalizacao.logo);
                state.commit("SET_CORES", {cor1: res.personalizacao.cor1, cor2: res.personalizacao.cor2});
                state.commit("SET_BACKGROUND_IMAGE", res.backgroundImage);
                state.commit("SET_PIZZA_COBRAR_MAIOR", res.pizzaCobrarMaior == null ? null : res.pizzaCobrarMaior.toString() == '0' ? false : true);
                state.commit("SET_IDS", {numeroEmpresaFK: res.numeroEmpresaFK, idgarcom: res.idgarcom, codigoCentroResultado: res.codigoCentroResultado, cnpj: res.cnpj, idTerminal: res.idTerminal});
                state.commit("SET_TEM_MERCADO_PAGO", res.mp);
                state.commit("SET_CARTAO_LEITOR_CODIGO_BARRAS", res.personalizacao.cartaoComLeitorCodigoBarras);
                
                this.dispatch("selecionarClasseInicial")
                return res;
            })
        },


        /**
         * @function setarCores()
         * @desc Metodo usado para setar todas as cores e logo definidas pelo usuario no allfood
         * @param {*} state 
         * @param {string} cor1 
         * @param {string} cor2 
         * @param {string} logo 
         * @returns Response
         */
        async setarCores(state, cor1, cor2, logo) {
            // state.commit("SET_CORES");
            state.commit("SET_LOGO", logo);
        },

        /**
         * @function darRefresh()
         * @param {*} state o current state
         * @param {*} payload o objeto com cnpj e idTerminal
         * @returns {object} response
         */
        async darRefresh(state, payload) {
            
            
            return buscarRefresh(payload.cnpj, payload.idTerminal, payload.isDelivery).then((res) => {
                // return buscarTodosItens(payload.isDelivery).then((res) => {
                state.commit("SET_LIMPAR_QUASE_TUDO_REFRESH");
                //

                state.commit("SET_DELIVERY", this.state.isDelivery); 

                state.commit("SET_PRODUTOS", res.classes);
                state.commit("SET_DESTAQUES", res.destaques);
                
                state.commit("SET_IDS", {numeroEmpresaFK: res.numeroEmpresaFK, idgarcom: res.idgarcom, codigoCentroResultado: res.codigoCentroResultado, cnpj: res.cnpj, idTerminal: res.idTerminal});
                this.dispatch("selecionarClasseInicial")
                return res;
            })
        },

        /**
         * 
         * @param {*} state 
         * @param {*} telefone telefone digitado
         */
        async enviarTelefone(state, telefone) {
            return new Promise((resolve, reject) => {
                if (telefone.length < 12 && telefone.length > 9) {
                        state.commit('SET_LOADING', true )

                       
 
                        
        
                            ApiDelivery.buscarCliente(this.state.cnpj, telefone)
                            
                                /** caso nao retorne erro, entao o cliente ja esta cadastrado. O retorno sera os dados do cliente */
                                .then(res => {
                                    state.commit('SET_LOADING', false)
            
                                    /** setar endereco no STATE */
                                    this.dispatch("setDeliveryEndereco", res.dadosEnderecos[0])
                                    
                                    /** Agora vamos buscar a taxa de entrega e ver se entregamos neste bairo */
                                    ApiDelivery.buscarTaxaDeEntrega(this.state.cnpj, this.state.numeroEmpresaFK, res.dadosEnderecos[0].bairro)
                                        .then(tax => {
                                            this.dispatch("setDeliveryTaxas", tax)
                                            resolve({
                                                dadosEnderecos: res.dadosEnderecos,
                                                dadosPessoais: res.dadosPessoais,
                                                delivery: tax,
                                            });
                                        }).catch(err2 => {
                                            console.error("Erro na taxa de entrega api :: " + err2);
                                            this.dispatch("setDeliveryTaxas", "erro") 
                                            reject(2424)
                                        })  
                                })

                                /** caso retorne erro 501 eh pq o cliente ainda nao foi cadastrado */
                                .catch(err => {
                                    console.error("Erro buscar cliente pelo numero :: " + err);
                                    state.commit('SET_LOADING', false) 
        
                                    /** vamos limpar o state caso ja tenha alguma coisa la */
                                    this.dispatch("setDeliveryTaxas", null) 
                                    this.dispatch("setDeliveryEndereco", null) 
                                    
                                    reject(501)
                                })
                                
                } else {
                    Swal.fire({title: "Telefone inválido"})
                    reject("telefone invalido");
                }
            })
            
        },

        /**
         * @function selecionarClasseInicial()
         * @description vai selecionar a classe inicial,  que é a primeira da lista de classes
         */
        async selecionarClasseInicial() {
            let obj = this.state.produtos[0];
            let payload = {
                titulo: obj["nome"],
                descricao: obj["descricao"],
                itensVenda: obj["itensVenda"]
            }
            this.dispatch('setClasseSelecionada', payload)
        },

        setIsMenuEstatico(state, payload) {
            state.commit("SET_IS_MENU_ESTATICO" ,payload);
        },
        
        setOrganizarPorColunas(state, payload) {
            state.commit("SET_ORGANIZAR_POR_COLUNAS" ,payload);
        },

        setLimparTudo(state) {
            state.commit("SET_LIMPAR_TUDO");
        },

        setIsDelivery(state, payload) {
            state.commit("SET_IS_DELIVERY", payload);
        },

        setCurrent(state, payload) {
            state.commit("SET_CURRENT", payload["age"])
        },
        /**
         * @function setClasseSelecionada()
         * @param {*} state deve trazer o estado inicial
         * @param {*} payload deve ser um objeto composto por {titulo: titulo da classe, itensVenda: itens da classe}
         */
        setClasseSelecionada(state, payload) {
            state.commit("SET_CLASSE_SELECIONADA", payload)
            state.commit("SET_ITENS_VENDA", payload.itensVenda)
        },
        setporCartao(state, payload) {
            state.commit("SET_POR_CARTAO", payload)
        },
        setItemSelecionado(state, payload) {
            state.commit("SET_ITEM_SELECIONADO", payload)
        },
        
        setEtapaSelecionada(state, payload) {
            state.commit("SET_ETAPA_SELECIONADA", payload)
        },

        setItensCarrinho(state, payload) {
            state.commit("SET_ITENS_CARRINHO", payload)
        },

        setDeletarItemCarrinho(state, payload) {
            state.commit("SET_DELETAR_ITEM_CARRINHO", payload)
        },

        setItemTemporario(state, payload) {
            state.commit("SET_ITEM_TEMPORARIO", payload)
        },

        setLimparItemTemporario(state) {
            state.commit('SET_LIMPAR_ITEM_TEMPORARIO')
        },

        setAnimarCarrinho(state) {
            state.commit('SET_ANIMAR_CARRINHO')
        },

        setAbaSelecionada(state, payload) {
            state.commit('SET_ABA_SELECIONADA', payload)
        },

        setStatusEConta(state, payload) {
            state.commit('SET_STATUS_E_CONTA', payload)
        },

        setMostrarBarraBusca(state, payload) {
            if (payload == null) {
                state.commit('SET_MOSTRAR_BARRA_BUSCA', !this.state.mostrarBarraBusca)
                if (!this.state.mostrarBarraBusca == false) {
                    state.commit("SET_ITENS_VENDA", [])
                }
            } else {
                state.commit('SET_MOSTRAR_BARRA_BUSCA', payload)
                if (payload == false) {
                    state.commit("SET_ITENS_VENDA", [])
                }
            }
        },
        setItemBuscado(state, payload) {
            if (payload.length != 0) {
                let todasClases = this.state.produtos
                let itensBuscados = []
                for (var i = 0; i < todasClases.length; i++) {
                    for (var ii = 0; ii < todasClases[i].itensVenda.length; ii++) {
                        if (todasClases[i].itensVenda[ii].nome.toLowerCase().includes(payload.toLowerCase())) {
                            itensBuscados.push(todasClases[i].itensVenda[ii])
                        }
                    }
                }
                state.commit("SET_ITENS_VENDA", itensBuscados)
                state.commit('SET_ITEM_BUSCADO', payload)
            } else {
                state.commit("SET_ITENS_VENDA", [])
                state.commit('SET_ITEM_BUSCADO', payload)
            }
        },
        setDeliveryEndereco(state, payload) {
            state.commit('SET_DELIVERY_ENDERECO', payload)
        },
        setDeliveryRetirarNoLocal(state, payload) {
            state.commit('SET_DELIVERY_RETIRAR_NO_LOCAL', payload)
        },
        setDeliveryTaxas(state, payload) {
            state.commit('SET_DELIVERY_TAXAS', payload)
        },
        setDeliveryPedido(state, payload) {
            state.commit('SET_DELIVERY_PEDIDO', payload)
        },
        setOnCLickEnviarPedido(state) {
            console.log(state.state.onClickEnviarPedido)
            state.commit('SET_ON_CLICK_ENVIAR_PEDIDO', state.state.onClickEnviarPedido + 1)
        },
        setCasaAberta(state, payload) {
            state.commit('SET_CASA_ABERTA', payload)
        },
    },
    modules: {},
    
    getters: {
        getEmpresa(state) {
            return state.empresa;
        },
        getCurrentStr(state) {
            return state.currentStr;
        },
        getItensVenda(state) {
            return state.itensVenda;
        },
        getItensCarrinho(state) {
            return state.itensCarrinho;
        },
        getItemTemporario(state) {
            return state.itemTemporario;
        },
        getCasaAberta(state) {
            return state.casaAberta;
        }
    }
})