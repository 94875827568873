<template>
    <div class="corpo-etapa">
        <div class="itens-da-etapa">
            <h3>{{ etapa.nome }}</h3>
            <h4 :class="classQtdMax">Selecione {{ isPizza ? "até" : "" }} {{ etapa.qtdeItensEscolhidosNaEtapa }} {{ isPizza ? "sabor(es)" : "item(ens)"}}</h4>
            <!-- <h5>{{ etapa.permiteEscolherMenosItens }}</h5> -->
            <div v-for="(item, index) in etapa.itens" :key="index">
                <div class="clicable" style="justify-content: space-between;" >
                    
                    <!-- <p>{{ JSON.stringify(item) }}</p> -->

                    <!-- caso seja PIZZA -->
                    <div v-if="isPizza"  :class="produtoSelecionado.findIndex(p => p.item.codigo  == item.codigo ) == -1 ? 'pizza-row' : 'pizza-row pizza-row-selecionado'" @click="selecionouSaborPizza(item)">
                        <div class="item-row">
                            <img v-if="produtoSelecionado.findIndex(p => p.item.codigo  == item.codigo ) !== -1" src="../../../assets//icons//ic-checked.png" alt="">
                            <img v-if="produtoSelecionado.findIndex(p => p.item.codigo  == item.codigo ) == -1" src="../../../assets//icons//ic-unchecked.png" alt="">
                            <div class="nome-e-descricao" >
                                <p class="nome-item">{{ item.descricaoCompleta }}</p>
                                <!-- so pra esparacar verticalmente o titlo e a descricao -->
                                <div v-if="item.observacao" style="height: 5px;"></div> 
                                <p class="descricao-item">{{ item.observacao }}</p>
                            </div>
                        </div>
  
                        <div class="pizza-row-precos" >
                            <!-- <p :class="qtdSaboresEscolhidosPizza > 1 && produtoSelecionado.findIndex(p => p.item.codigo  == item.codigo ) !== -1 ? 'preco-pizza-integral-inativo' : 'preco-pizza-integral'" active > R$ {{ item.valorVenda.toFixed(2).replace(".", ",") }}</p> -->
                            <p  
                                v-if="produtoSelecionado.findIndex(p => p.item.codigo  == item.codigo ) == -1 "
                                class="preco-pizza-fracao"  
                            > 
                                R$ {{ item.valorVenda.toFixed(2).replace(".", ",") }}
                            </p>


                            
                            <div 
                                class="preco-pizza-fracao" 
                                v-if="qtdSaboresEscolhidosPizza > 0 && produtoSelecionado.findIndex(p => p.item.codigo  == item.codigo ) !== -1"  
                            >
                                <p v-if="qtdSaboresEscolhidosPizza > 1 " style="color: rgb(180,180,180); font-size: 0.8rem;">(1/{{ qtdSaboresEscolhidosPizza }})</p>
                                <p>R$ {{ calcularValorFracaoPizza(item.valorVenda).toFixed(2).replace(".", ",") }}</p>
                                 
                            </div>
                        </div>
                    </div>

                    <!-- caso NAO seja pizza  -->
                    <div v-if="!isPizza" class="etapas-row" >
                        <div class="item-row" >
                            <div @click="removerItem(item)">
                                <img src="../../../assets/icons/ic_trash_x.png" alt="" style="height: 30px;" :class="qtdDesteItemNaLista(item) == 0 && 'box-number-zero'">
                            </div>
                            <div class="item-row"  @click="adicionarItem(item)">
                                <div :class="qtdDesteItemNaLista(item) == 0 ? 'box-number box-number-zero' : 'box-number'">
                                    <p>{{ qtdDesteItemNaLista(item) }}</p>
                                </div>
                                <p style="text-align: start;">{{ item.descricaoCompleta }}</p>
                            </div>
                        </div>

                        
                        <p @click="adicionarItem(item)">R$ {{  cobrarPorEtapa ? etapa.valorEtapa.toFixed(2).replace(".", ",") : item.valorVendaItem.toFixed(2).replace(".", ",") }}</p>
                        
                    </div>

                </div>
            </div>
        </div>
   
        <hr>

        <div v-for="(selecionado, index) in produtoSelecionado" :key="index">
            <ProdutoController
                :item="selecionado.item"
                :itemIndex="index"
                :isSaborPizza="isPizza"
                :qtdSaboresEscolhidos="qtdSaboresEscolhidosPizza"
                :precoProps="cobrarPorEtapa ? etapa.valorEtapa : null"
                @onClickConfirmarEsteItem="onItemSelecionadoFinalizado"
            />
        </div>

        <div v-if="!isPizza" class="btn-avancar-bottom" @click="callbackItem" >
            <!-- <button :class="podeAvancar ? `btn-avancar` : `btn-avancar desativado`" @click="callbackItem">Avançar</button> -->
            <EtapasBotaoAvancar 
            :itensNaEtapa="etapa.qtdeItensEscolhidosNaEtapa" 
            :itensRespondidos="this.produtoSelecionado.length" 
            :permiteEscolherMenosItens="etapa.permiteEscolherMenosItens == 1 || etapa.permiteEscolherMenosItens == '1'"
            :perguntasObrigatoriasRepondidas="podeAvancar"
            :isPrimeiraEtapa="indexEtapaCorrente == 0"
            />
        </div>

        <div v-if="isPizza" class="btn-avancar-bottom-pizza" >
            <button :class="podeAvancar ? `btn-avancar` : `btn-avancar desativado`" @click="callbackItem">Avançar</button>
        </div>

    </div> 
</template>

<script>
import EtapasBotaoAvancar from '@/components/Base/EtapasBotaoAvancar.vue'
import ProdutoController from './controle/ProdutoController.vue'

export default {
    name: "CadaEtapa",
    props: {
        indexEtapaCorrente: Number,
        totalEtapas: Number,
        isPizza: Boolean,
        etapa: Object,
        cobrarPorEtapa: Boolean,
    },
    watch: {
        etapa() {
            this.produtoSelecionado = []
            this.produtosFinalizados = []
        }
    },
    computed: {

        qtdSaboresEscolhidosPizza() {
            return this.produtoSelecionado.length
        },

        podeAvancar() {
            if (this.isPizza) {
                return this.produtoSelecionado.length > 0
            } else {

                // se permite escolher menos itens do que o maximo
                if (this.etapa.permiteEscolherMenosItens == 1 || this.etapa.permiteEscolherMenosItens == "1") {

                    // se permite escolher menos itens e essa é a PRIMEIRA etapa, o usuario é obrigado a escolher
                    // pelo menos um item. Se for da segunda etapa pra frente, o usuario pode escolher
                    // nenhum item e ir para a proxima etapa

                    if (this.indexEtapaCorrente == 0) { // se for a primeira etapa
                        if (this.numeroDePerguntasObrigatoriasRespondidas == parseInt(this.produtoSelecionado.length) && this.produtoSelecionado.length > 0) {
                            return true
                        }
                        return false
                    } else {
                        if (this.numeroDePerguntasObrigatoriasRespondidas == parseInt(this.produtoSelecionado.length)) {
                            return true
                        }
                        return false
                    }
                        
                } 
                
                // caso tenha que escolher exatamente o numero maximo de itens
                else {
                    
                    if (this.produtoSelecionado.length == this.etapa.qtdeItensEscolhidosNaEtapa) {
                        if (this.numeroDePerguntasObrigatoriasRespondidas == parseInt(this.etapa.qtdeItensEscolhidosNaEtapa)) {
                            return true
                        }
                        return false
                    }
                    return false
                }
            }
        },

        numeroDePerguntasObrigatoriasRespondidas() {
            let lista= this.produtosFinalizados.filter((i) => i.podeAvancar);
            // let lista2 = this.produtosFinalizados.length;
            return lista.length
        },

        computedPizzaSaborMaisCaro() { 
            // Sort the array by price in descending order
            let newarray = [...this.produtoSelecionado];
            newarray.sort((a, b) => Number(b.item.valorVenda) - Number(a.item.valorVenda));
            // Get the highest price
            const precoMaisAlto = newarray[0].item.valorVenda;
            return precoMaisAlto
        }

        

    },
    data() {
        return {
            classQtdMax: 'qtd-max',
            // podeAvancar: false,
            produtoSelecionado: [],
            produtosFinalizados: [],
        };
    },
    methods: {


        calcularValorFracaoPizza(valorIntegral) { 
            let cobrarMaior = this.$store.state.pizzaCobrarMaior;
            if (cobrarMaior == false) {
                return (1 / this.qtdSaboresEscolhidosPizza) * valorIntegral
            } else {
                if (this.qtdSaboresEscolhidosPizza < 2) {
                    return (1 / this.qtdSaboresEscolhidosPizza) * valorIntegral
                }
                return (1 / this.qtdSaboresEscolhidosPizza) * Number(this.computedPizzaSaborMaisCaro)
            }
        },


        /**
         * APENAS PARA PIZZAS
         * 
         * Vai adicionar o sabor, ou retirar, ja que nao vamos adicionar 2/3 de um sabor e 1/3 de outro.
         * Ou seja, nao podemos selecionar duas vezes o mesmo sabor
         * @param {*} item
         */
        selecionouSaborPizza(item) {

            let itemPreFinalizado = {
                podeAvancar: item.podeAvancar ?? false,
                item: item,
                perguntasForcadas : [],
                itemsRetirar      : [],
                itemsAdicionar    : [],
            } 

            let index = this.produtoSelecionado.findIndex(i => i.item.codigo == item.codigo)
            if (index == -1) {
                if (this.produtoSelecionado.length !== parseInt(this.etapa.qtdeItensEscolhidosNaEtapa) ) {
                    this.produtoSelecionado.push(itemPreFinalizado);
                } else {
                    this.animarQuantidadeMaxAtingida()
                }
            } else {
                this.produtoSelecionado = this.produtoSelecionado.filter(i => i.item.codigo !== item.codigo);
                this.produtosFinalizados = this.produtosFinalizados.filter((i) => i.item.codigo !== item.codigo)
            }

        },

        /**
         *  APENAS COMBO POR ETAPA
         *  Adicioanr itens na lista. Aqui podemos colocar dois itens iguas.
         */
        adicionarItem(item) {
            if (this.produtoSelecionado.length < this.etapa.qtdeItensEscolhidosNaEtapa) {

                let itemPreFinalizado = {
                    podeAvancar: false,
                    item: item,
                    perguntasForcadas : [],
                    itemsRetirar      : [],
                    itemsAdicionar    : [],
                }
                this.produtoSelecionado.push(itemPreFinalizado);
            } else {
                this.animarQuantidadeMaxAtingida()
            }
            // this.produtosFinalizados.push(itemPreFinalizado);
        },

        /**
         * APENAS COMBO POR ETAPA
         * Remover item da lista. Pode haver dois itens iguais na lista, entao retiramos um deles apenas.
         */
        removerItem(item) {
            let index = this.produtoSelecionado.findIndex(i => i.item.codigo == item.codigo)
            if (index !== -1) {
                this.produtoSelecionado.splice(index, 1);
            }

            let index2 = this.produtosFinalizados.findIndex(i => i.item.codigo == item.codigo)
            if (index2 !== -1) {
                this.produtosFinalizados.splice(index, 1);
            }
        },

        qtdDesteItemNaLista(item) {
            if (item != null) {
                const theseItems = this.produtoSelecionado.filter(i => i.item.codigo == item.codigo);
                return theseItems.length;
            }
            return 0
        },

        animarQuantidadeMaxAtingida() {
            window.scrollTo(0, 0);
            this.classQtdMax = 'qtd-max-anim'
            setTimeout(() => {
                this.classQtdMax = 'qtd-max'
            }, 1000);
        },



        /**
         * Vai adicionar o item finalizado na @see this.produtosFinalizados . Caso o item ja esteja la,
         * vai apagar ele de la e adicionar o novo
         * @param {*} item 
         */
        onItemSelecionadoFinalizado(item, itemIndex) {
            
            let itemPreFinalizado = item
            
            if (this.produtosFinalizados[itemIndex] == null) {
                this.produtosFinalizados.push(itemPreFinalizado)
            } else {
                let placeeholder = this.produtosFinalizados
                this.produtosFinalizados = [];
                this.produtosFinalizados = placeeholder;
                this.produtosFinalizados[itemIndex] = itemPreFinalizado;
            }

        },

        /**
         * Enviar para o parent o item selecionado
         * @param {*} item
         */
        callbackItem() {
            if (this.podeAvancar) {
                this.$emit("onSelectedItems", this.produtosFinalizados);
            }
                
        },
    },
    components: { ProdutoController, EtapasBotaoAvancar }
}
</script>

<style scoped>

.pizza-row, .etapas-row {
    margin-block: 8px;
    padding-block: 8px;
}

.corpo-etapa {
    margin: 0;
    overflow-x: hidden;
}

.corpo-etapa > .itens-da-etapa{
    padding-block: 10px;
    margin-left: 0;
    background: linear-gradient(to bottom, var(--cor1-escura),black);
}

p {color: white; margin: 0;}

.itens-da-etapa {
    padding-inline: 10px;
}

.etapas-row {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    margin-block: 10px;
}

.item-row { display: flex; justify-content: start; align-items: center; padding-block: 8px; width: 77%; }

.item-row img {height: 20px; filter: invert(100%); margin-right: 10px;}

.item-row .nome-e-descricao {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.item-row .nome-item {
    font-weight: bold;
}

.item-row .descricao-item {
    font-size: 0.8rem;
    line-height: 1.1rem;
    color: rgba(205,205,205);
    text-align: justify;
}

.qtd-max {
    
}
.qtd-max-anim {
    animation: qtd-max-animation 1s forwards;
}

@keyframes qtd-max-animation {
    0%, 100%                 { transform: translateX(0%); background: none }
    10%, 30%, 50%, 70%, 90%  { transform: translateX(-2%); background: red}
    20%, 40%, 60%, 80%       { transform: translateX(2%); background: red}
}

.btn-avancar {
    height: 50px;
    width: 100%;
}

.desativado {
    background: rgb(100, 100, 100);
    color: rgb(75, 75, 75);
}

.btn-avancar-bottom {
    width: calc(100% - 20px);
    position: sticky;
    bottom: 0;
    left: 0;
    /* height: 75px; */
    background: white;
    padding: 6px;
}

.btn-avancar-bottom-pizza {
    width: calc(100% - 20px);
    /* height: 75px; */
    background: white;
    padding: 6px;
}


/* ----------------------------------------------------------------------- APENAS PARA PIZZAS */

.pizza-row {
    
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 15px; */
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}
.pizza-row-precos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-block: 10px;
    width: 23%;
} 
.pizza-row-selecionado {
    /* background: rgba(0, 0, 0, 0.5); */
    border: solid 1px white;
    padding-inline: 8px;
    background: rgba(255, 255, 255, 0.1);
}
.preco-pizza-integral {
    text-align: end;
}
.preco-pizza-integral-inativo {
    opacity: 0.6;
    font-size: 0.9rem;
    text-align: end;
    margin-bottom: 4px;
}
.preco-pizza-fracao {
    animation: preco-pizza-fracao-anim 0.5s forwards;
    text-align: end;
    font-size: 0.9rem;
    color: rgba(255,255,255, 0.8);
}
@keyframes preco-pizza-fracao-anim {
    from { opacity: 0; transform: translateY(30%); }
    to   { opacity: 1; transform: translateY(0%); }
}

.box-number {
    border: solid 1px white;
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
}

.box-number-zero {
    opacity: 0.4;
}
.box-number p {
    margin: 0;
    font-size: 0.8rem;
}
</style>